import styled from 'styled-components';
import { Button } from '@mui/material';

import { textDisabledDarkThemeColor } from '/common/theme';

export const ScriptsMenuToggler = styled(Button)`
  color: #fff;

  &.Mui-disabled {
    color: ${textDisabledDarkThemeColor};
  }

  .MuiButton-endIcon {
    margin-left: 4px;
  }
`;
